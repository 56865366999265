<template>
    <b-row>
        <b-col lg="12" class="mb-1">
            <div class="d-flex flex-row justify-content-center align-items-center" style="height: 100%;">
                <b-card-text class="text-center">
                    <b-container>
                        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" to="/affaire_types/add">
                            <feather-icon class="text-primary mr-50 align-middle" icon="PlusSquareIcon" size="30"/>
                            <span class="text-primary align-middle">Ajouter Affaire Type</span>
                        </b-button>
                    </b-container>
                </b-card-text>
            </div>
        </b-col>

        <b-col lg="12">
            <b-card>
                <!-- table -->
                <vue-good-table
                    :columns="columns"
                    :rows="affaireTypes"
                    :pagination-options="{enabled: true, perPage:pageLength}"
                    :initialSortBy="initialSortBy"
                    :theme="isDark ? 'nocturnal' : ''"
                    styleClass="vgt-table bordered tableau"
                    >
                >
                    <template #emptystate>
                        <div class="text-center"> Aucun type d'affaire n'a été trouvée </div>
                    </template>

                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'action'" class="text-nowrap">
                            <b-button
                                size="sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="success"
                                class="btn-icon"
                                @click="editAffaireType(props.row.id)"
                            >
                                <feather-icon icon="Edit2Icon" size="1x"/>
                            </b-button>

                            <b-button
                                size="sm"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="danger"
                                class="btn-icon ml-1"
                                @click="confirmDeleteAffaireType(props.row.id, props.row.nom, removeAffaireTypeFromList)"
                            >
                                <feather-icon icon="TrashIcon" size="1x"/>
                            </b-button>
                        </span>
                    </template>

                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['25', '50', '100']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
                </vue-good-table>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table'

    import miscApi from "@/api/miscApi"
    import { confirmDeleteAffaireType, deleteAffaireType } from "@/views/setups/affaire_type"
    import {mapState, mapMutations} from "vuex"

    import useAppConfig from '@core/app-config/useAppConfig'
    import { computed } from '@vue/composition-api'

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            VueGoodTable,
            ToastificationContent
        },

        data() {
            return {
                initialSortBy: [{field: 'nom', type: 'asc'}],
                pageLength: 25,
                columns: [
                    {
                        label: 'Code',
                        field: 'code',
                        filterOptions: {enabled: true,placeholder: 'Code ...',},
                    },
                    {
                        label: 'Libellé',
                        field: 'libelle',
                        filterOptions: {enabled: true,placeholder: 'Libellé ...',},
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        width: '10%',
                        sortable: false
                    },
                ],
            }
        },

        beforeMount() {
            this.refreshAffaireTypes()
        },

        setup() {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')

            return { skin, isDark, deleteAffaireType, confirmDeleteAffaireType}
        },

        computed: {
            ...mapState({
                storeAffaireTypes: state => state.data.affaireTypes
            }),

            affaireTypes: {
                get() {return this.storeAffaireTypes},
                set(affaireTypes) {this.setAffaireTypes(affaireTypes)}
            }
        },

        methods: {
            ...mapMutations("data", ["setAffaireTypes", "removeAffaireTypeFromList"]),

            async refreshAffaireTypes() {
                /* console.log(process.env.NODE_ENV == "development") */
                this.affaireTypes = await miscApi.getAffaireTypes();
            },

            editAffaireType(affaire_typeId) {
                this.$router.push({path: `/affaire_types/${affaire_typeId}/edit` })
            },

            showToast(variant, text, icon) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        icon,
                        text,
                        variant
                    },
                }, {position: "bottom-right", timeout: 2500})
            },
        }
    }
</script>

<style>
    .dropdown > button{
        padding: 0 !important;
    }
    
</style>